import StorefrontIcon from '@material-ui/icons/Storefront';

export default {
  config: {
    icon: StorefrontIcon,
    options: {
      label: 'La Boutique'
    }
  }
};
