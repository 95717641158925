import LanguageIcon from '@material-ui/icons/Language';

export default {
  config: {
    icon: LanguageIcon,
    options: {
      label: 'Colibris'
    }
  }
};
