import LocalFloristIcon from "@material-ui/icons/LocalFlorist";

export default {
  config: {
    icon: LocalFloristIcon,
    options: {
      label: 'La Fabrique'
    }
  }
};
