import SchoolIcon from '@material-ui/icons/School';

export default {
  config: {
    icon: SchoolIcon,
    options: {
      label: "L'Université"
    }
  }
};
