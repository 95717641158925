import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';

export default {
  config: {
    icon: LocalLibraryIcon,
    options: {
      label: 'Le Mag'
    }
  }
};
