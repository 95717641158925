import RoomIcon from '@material-ui/icons/Room';

export default {
  config: {
    icon: RoomIcon,
    options: {
      label: 'Près de chez nous'
    }
  }
};
