import GroupWorkIcon from '@material-ui/icons/GroupWork';

export default {
  config: {
    icon: GroupWorkIcon,
    options: {
      label: 'Groupes locaux'
    }
  }
};
